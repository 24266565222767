<!--  -->
<template>
  <div>

    <div class="modellist-wrapper">

      <div class="modellist-content-box" :class="{ 'scroll-flag': iflag }">
        <div class="modellist-title-box">
          <p class="modellist-titlep1">慧言AI，专业的AI员工训练工具，为您的企业降本增效</p>
          <p class="modellist-titlep2">上传训练文档，一键创建专家级数字员工</p>
          <div class="modellist-title-btn">
            <div class="modellist-title-btn1" @click="openConversion(1)">
              立即对话
            </div>
            <div class="modellist-title-btn2" @click="createEmp(1)">
              创建助理/员工

              <div class="modellist-title-btntip">
                免费使用
              </div>

            </div>
          </div>

        </div>


        <div v-for="(item, index) in modelList" :key="item.catalogId" class="div-modellist">


          <p class="modellist-contentp">
            {{ item.catalogDesc }}
          </p>
          <div class="modellist-content">
            <div class="modellist-contentitem" v-for="(item2, index) in item.empMarket" :key="item2.empId">
              <div class="item-title">
                <img :src="item2.realEmpHeader ? item2.realEmpHeader : item2.empHeader" class="item-titleimg" />
                <p class="item-titlep">
                  {{ item2.empName }}
                </p>
              </div>
              <p class="item-remark">{{ item2.remark }}</p>
              <p style="flex: 1;"></p>
              <div class="item-btnpar">
                <div class="item-btn2" v-loading="item.btnloading"
                  @click="shareEmpBtn(item2.empId, item, item2.shareEmpId)">
                  <div class="item-btnimg2"></div>
                  <p class="item-btnp2">开始对话</p>
                </div>

                <div class="item-btn2" @click="createSameEmpBtn(item, item2.empId)">
                  <div class="item-btnimg3"></div>
                  <p class="item-btnp2">创建同款</p>
                </div>

              </div>

            </div>
          </div>



        </div>






      </div>






      <iframe :src="isrc" width='100%' frameborder="0" class="ifr-box" v-if="iflag"></iframe>

      <!-- <transition enter-active-class="animate__animated animate__slideInUp"
        leave-active-class="animate__animated animate__zoomOutDown">
        
      </transition>-->




      <div class="div-closecon" v-show="iflag" @click="closeConver">
        <img src="https://cdn.wiseai.chat/APP/sys_img/digitalEmployee/aipcfanhui.png" class="delete-img" />

        <p>返回助理/员工市场</p>

      </div>


    </div>
  </div>
</template>

<script>
import common from "common/js/common";
import {
  empMarket, empMarketAnon,
  addShareEmpToAsk, addRecommendEmp
} from "api/aiworker/aiworker.js";
export default {
  data () {
    return {
      iflag: false,
      isrc: '',
      btnloading: false,
      modelList: [],
      workbenchesStaffArr: [],
      shareList: [],
      btnimg2: 'https://cdn.wiseai.chat/APP/sys_img/digitalEmployee/aidhh.png',
    }
  },

  computed: {

    access_token () {
      return this.$store.state.user.access_token
    },
  },
  methods: {

    openConversion (index) {

      if (common.isStringEmpty(this.access_token)) {
        this.$emit("openloginAction", 2);
      } else {
        this.iflag = true;
        if (index == 1) {
          this.isrc = this.$currentServe + 'conversation/#/?' + this.modelList[0].empMarket[0].empNo;
        } else {
          this.isrc = this.$currentServe + 'conversation/#/?' + index;
        }
        sessionStorage.setItem("empLocation", "2");
      }
    },
    createEmp (index) {
      if (common.isStringEmpty(this.access_token)) {
        this.$emit("openloginAction", 1);
      } else {
        if (index == 1) {
          // window.open(this.$currentServe + "backend/#/emp/myyg");
          top.location.href = this.$currentServe + "backend/#/emp/myyg";
        }
      }
    },

    closeConver () {
      this.iflag = false;

    },

    refreshModel2 (index, id) {

      if (common.isStringEmpty(this.access_token)) {
        this.getempMarketAnnoList();
      } else {
        this.getempMarketList(index, id);
      }

    },
    shareEmpBtn (id1, item, id) {
      if (common.isStringEmpty(this.access_token)) {
        this.$emit("openloginAction", 4, id1);
      } else {

        this.shareEmpAction(item, id);
      }


    },

    createSameEmpBtn (item, id) {
      if (common.isStringEmpty(this.access_token)) {
        this.$emit("openloginAction", 5, id);
      } else {
        this.createSameEmpAction(item, id);
      }

    },

    createSameEmpAction (item, id) {



      addRecommendEmp(id).then((res) => {
        console.log(res);

        if (res.code == 200) {

          this.$message({
            message: '创建成功',
            type: 'success',
            duration: 1000,
            onClose: () => {
              //此处写提示关闭后需要执行的函数
              top.location.href = this.$currentServe + "backend/#/emp/myyg";
            }
          });





        } else {
          if (!common.isStringEmpty(res.message)) {
            this.$message.error(res.message);
          }

        }
      }).catch((err) => {

        if (!common.isStringEmpty(JSON.stringify(err))) {
          this.$message.error(JSON.stringify(err.message));
        }

      });


    },



    shareEmpAction (item, id) {
      item.btnloading = true;
      addShareEmpToAsk(id).then((res) => {
        console.log(res);
        item.btnloading = false;
        if (res.code == 200) {

          this.openConversion(res.data.empNo);
        } else {
          if (!common.isStringEmpty(res.message)) {
            this.$message(res.message);
          }

        }
      }).catch((err) => {
        item.btnloading = false;
        if (!common.isStringEmpty(JSON.stringify(err))) {
          this.$message(JSON.stringify(err.message));
        }

      });
    },


    getempMarketAnnoList () {
      empMarketAnon().then((res) => {
        console.log(res);
        if (res.code == 200) {

          this.modelList = res.data;
          this.modelList.map(item => {
            this.$set(item, 'btnloading', false)
            this.$set(item, 'btnloading2', false)
            return item
          })

        } else {
          if (!common.isStringEmpty(res.message)) {
            this.$message(res.message);
          }

        }
      }).catch((err) => {
        if (!common.isStringEmpty(JSON.stringify(err))) {
          this.$message(JSON.stringify(err.message));
        }

      });
    },
    getempMarketList (index, id) {
      empMarket(index, id).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.modelList = res.data;
          this.modelList.map(item => {
            this.$set(item, 'btnloading', false)
            this.$set(item, 'btnloading2', false)
            return item
          })



          if (index == -1) {
            console.log("indexindex" + index);
            this.iflag = true;
            this.isrc = this.$currentServe + 'conversation/#/?' + this.modelList[0].empMarket[0].empNo;
          } else if (index = 1) {
            console.log("indexindexindexindex" + index);
            for (let i = 0; i < this.modelList.length; i++) {
              for (let j = 0; j < this.modelList[i].empMarket.length; j++) {
                if (id == this.modelList[i].empMarket[j].empId) {
                  //shareEmpBtn(item,item2.shareEmpId)

                  this.shareEmpAction(this.modelList[i], this.modelList[i].empMarket[j].shareEmpId);
                  return false;
                }
              }
            }

          } else if (index == 2) {
            for (let i = 0; i < this.modelList.length; i++) {
              for (let j = 0; j < this.modelList[i].empMarket.length; j++) {
                if (id == this.modelList[i].empMarket[j].empId) {
                  //shareEmpBtn(item,item2.shareEmpId)

                  this.createSameEmpAction(this.modelList[i], this.modelList[i].empMarket[j].empId);
                  return false;
                }
              }
            }
          }





        } else {
          if (!common.isStringEmpty(res.message)) {
            this.$message(res.message);
          }

        }
      }).catch((err) => {
        if (!common.isStringEmpty(JSON.stringify(err))) {
          this.$message(JSON.stringify(err.message));
        }

      });
    },


  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {
    console.log("modelmodelmodelmodelmodel");

    if (common.isStringEmpty(this.access_token)) {
      this.getempMarketAnnoList();
    } else {
      this.getempMarketList(0);
    }


  }
}
</script>
<style scoped lang="less">
/* @import url(); 引入css类 */


.modellist-wrapper {
  position: relative;


}

.ifr-box {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100vh - 56px);
  margin-top: 56px;



}





.modellist-content-box {
  width: 1200px;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

}

.scroll-flag {
  overflow: hidden;
}

.modellist-title-box {
  width: 792px;
  height: 243px;
  margin-top: 50px;
  background-image: url('https://cdn.wiseai.chat/APP/sys_img/digitalEmployee/aijbbj.png');

  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;

  .modellist-titlep1 {
    font-size: 26px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 26px;
    margin-top: 80px;
  }

  .modellist-titlep2 {
    font-size: 14px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, .6);
    line-height: 14px;
    margin-top: 14px;
  }

  .modellist-title-btn {
    display: flex;
    margin-top: 30px;
  }

  .modellist-title-btn1 {
    width: 140px;
    height: 48px;
    background: linear-gradient(138deg, #735CFF 0%, #7785FF 100%);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    cursor: pointer;
  }

  .modellist-title-btn1:hover {
    transform: scaleX(1.05) scaleY(1.05) !important;
  }

  .modellist-title-btn2 {
    width: 140px;
    height: 48px;
    background: linear-gradient(315deg, #0085FF 0%, #226CFF 100%);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  .modellist-title-btn2:hover {
    transform: scaleX(1.05) scaleY(1.05) !important;
  }

  .modellist-title-btntip {
    position: absolute;
    width: 68px;
    height: 23px;
    background: linear-gradient(90deg, #FFF0CA 0%, #FFE8BA 100%);
    border-radius: 12px 12px 12px 12px;
    opacity: 1;
    font-size: 13px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #876134;
    line-height: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -12px;
    top: 0;
    right: 0;
  }

}

.div-modellist {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.modellist-contentp {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, .5);
  line-height: 14px;
  margin-left: 10px;
  margin-top: 20px;

  align-self: flex-start;
}

.modellist-content {

  display: flex;
  width: 100%;
  flex-wrap: wrap;

}

.modellist-contentitem {

  display: flex;
  flex-direction: column;
  margin: 10px 10px;
  flex: 0 0 calc((100% - 65px) / 3);
  height: 168px;

  border: 1px solid #E6EBF3;
  background: #FFFFFF;
  box-shadow: 0px 6px 18px 0px #DAE8F3;
  border-radius: 8px 8px 8px 8px;
  cursor: pointer;
}

img[src=""],
img:not([src]) {
  opacity: 0;
}

.item-title {
  display: flex;
  margin-top: 13px;
  margin-left: 14px;
  align-items: center;
}

.item-titleimg {
  width: 46px;
  height: 46px;
  border-radius: 8px 8px 8px 8px;

}

.item-titlep {
  font-size: 16px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 16px;
  margin-left: 10px;
}

.item-remark {

  margin-left: 14px;
  margin-right: 14px;
  font-size: 14px;

  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, .5);
  line-height: 18px;
  margin-top: 10px;
  /* 设置多行*/
  overflow: hidden;
  text-overflow: ellipsis;
  /* 超出部分省略号 */
  word-break: break-all;
  /* 设置省略字母数字 */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.item-btnpar {
  display: flex;
}

.item-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  background: #FFFFFF;
  border-radius: 8px 8px 8px 8px;

  border: 1px solid #E6EBF3;
  margin-left: 14px;
  margin-right: 14px;
  margin-bottom: 14px;

  .item-btnp {
    font-size: 14px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #818181;
    line-height: 14px;
  }
}

.item-btnimg {
  width: 14px;
  height: 14px;
  margin-right: 4px;
  background-image: url("https://cdn.wiseai.chat/APP/sys_img/digitalEmployee/aidhh.png");


  background-size: 100% 100%;
  background-repeat: no-repeat;

}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition-duration: .15s;
}

.modellist-contentitem:hover {
  // border: 1px solid #65AAFB;
  // margin-bottom: 20px;
  transform: translateY(-5px);
}

.item-btn:hover {
  background: #226CFF;

  .item-btnp {
    color: #ffffff;
  }

  .item-btnimg {

    background-image: url("https://cdn.wiseai.chat/APP/sys_img/digitalEmployee/aidhbai.png");


  }
}

.item-btnpar {
  display: flex;
  margin-left: 8px;
  margin-right: 8px;
}

.item-btn2 {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  background: #FFFFFF;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #E6EBF3;
  margin-left: 6px;
  margin-right: 6px;
  margin-bottom: 14px;

}

.item-btnimg2 {
  width: 14px;
  height: 14px;
  margin-right: 4px;
  background-image: url("https://cdn.wiseai.chat/APP/sys_img/digitalEmployee/aidhh.png");
  background-size: 100% 100%;
  border: none;
  outline: none;
  box-shadow: none;


}

.item-btnimg3 {
  width: 14px;
  height: 14px;
  margin-right: 4px;
  background-image: url("https://cdn.wiseai.chat/APP/sys_img/digitalEmployee/aidhtj.png");
  border: none;
  outline: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  background-size: cover;


}

.item-btnp2 {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #818181;
  line-height: 14px;
}

.item-btn2:hover {
  background: #226CFF;

  .item-btnp2 {
    color: #ffffff;
  }

  .item-btnimg2 {

    background-image: url("https://cdn.wiseai.chat/APP/sys_img/digitalEmployee/aidhbai.png");
  }

  .item-btnimg3 {

    background-image: url("https://cdn.wiseai.chat/APP/sys_img/digitalEmployee/aidhhtjb.png");
  }
}

.div-closecon {
  display: flex;

  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 40px;
  cursor: pointer;
  margin-top: 73px;
  z-index: 10;
}

.div-closecon p {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, .6);
  line-height: 14px;
  margin-left: 3px;
}

.delete-img {


  width: 16px;
  height: 16px;


}
</style>